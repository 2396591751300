<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="send-mail-box">
      <h2 class="fw-normal fs-3 mb-0 text-dark">Review &amp; Send</h2>
      <p class="fw-normal fs-7">Message to Recipients</p>
      <div id="sendDocumentDialog">
        <el-form ref="form">
          <el-row justify="center" :gutter="30">
            <el-col :lg="10" :sm="24">
              <el-form-item label="To Recipients">
                <el-select
                  v-model="mailSendTo"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  class="w-100 border-0"
                  placeholder="Add Recipients"
                  disabled
                >
                  <el-option
                    v-for="(contact, index) in contactRecipients"
                    :key="index"
                    :label="contact.email"
                    :value="contact.email"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="CC Recipients">
                <el-select
                  v-model="mailCCSendTo"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  class="w-100 border-0"
                  placeholder="Add CC Recipients"
                  disabled
                >
                  <el-option
                    v-for="(contact, index) in contactRecipients"
                    :key="index"
                    :label="contact.email"
                    :value="contact.email"
                    :disabled="fetchSignUsers.indexOf(contact.email) != -1"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="14" :sm="24">
              <el-form-item label="Email Subject">
                <el-input
                  v-model="mailSubject"
                  type="textarea"
                  placeholder="Subject"
                  :autosize="{ minRows: 2 }"
                  maxlength="70"
                  show-word-limit
                >
                </el-input>
                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining : 70</div> -->
              </el-form-item>
              <el-form-item label="Message">
                <el-input
                  type="textarea"
                  v-model="mailContent"
                  placeholder="Type your text here"
                  class="border-0"
                  rows="8"
                  maxlength="10000"
                  show-word-limit
                ></el-input>
                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining : 10000</div> -->
              </el-form-item>
              <div class="card-footer mt-1 text-right">
                <el-button @click="goBack" class="fs-7">Back</el-button>
                <el-button
                  type="danger"
                  class="fs-7"
                  @click="checkIsShowSettignsVisible"
                  >Send Document</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-dialog
      :visible.sync="sentDocumentSuccess"
      class="dialog-success outersize-confirm-type-three"
      title="Document Sent "
      :before-close="handleCloseDialog"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>
        <img
          class="imgstyle"
          alt="Document Sent"
          src="@/assets/img/sentDocumentSuccess.gif"
        />
        <p style="font-weight: 600px" class="sent-text">
          Document has been sent
        </p>
      </span>

      <p class="pstyle" style="width: 100%">
        we will send you an email notification when any action is taken on the
        document:
      </p>
      <p
        class="subpstyle"
        style="text-align: center; width: 100%; margin-left: 0px"
      >
        if someone view,signs or declined etc...
      </p>

      <div
        style="-ms-transform: translateX(-30%); transform: translateX(31%)"
        class="dialog-footer"
      >
        <el-button @click="saveDocumentAsTemplate" style="margin-bottom: 5px"
          >Save as Template</el-button
        >
        <el-button
          id="doc"
          type="primary"
          @click="gotoViewDocument"
          style="margin-bottom: 5px"
          >View Document</el-button
        >
      </div>
    </el-dialog>
    <ConfigureSettings
      v-if="docSettingsVisible"
      :settingsData="getDefaultSettings"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
      @updateSettingsData="createSendDoc"
    ></ConfigureSettings>
  </div>
</template>

<script>
const ConfigureSettings = () =>
  import("@/components/companyDocuments/configureDocuments/ConfigureSettings");
import { mapGetters } from "vuex";
export default {
  name: "companyDocuments-send-Template-OnMail",
  components: { ConfigureSettings },
  data() {
    return {
      contactRecipients: [],
      mailSendTo: [],
      mailCCSendTo: [],
      mailContent: "",
      mailSubject: "",
      confDocID: "",
      loading: false,

      // configure document details
      backgroundImages: [],
      documentName: "",
      pages: [],
      storedFormBuilderWidth: null,
      templateTitle: "",
      documentUsers: [],
      elements: [],

      enforceSignatureOrder: true,
      docSettingsVisible: false,
      sentDocumentSuccess: false,
      showSettings: false,
      sender_auto_fill: false,
      isAnyoneCanApprove: false,
      newDocumentUrl: "",
      document_url_path: "",
      loadingText: "",
    };
  },
  async mounted() {
    this.loading = true;
    this.loadingText = "Hang tight! Your document is almost ready to send...";
    this.confDocID = this.$route.params.id;
    await Promise.all([
      this.fetchDocumentDetails(),
      this.$store.dispatch("contacts/fetchAllContacts", {
        get_all: true,
        type: "CONTACT",
      }),
      this.$store.dispatch("settings/fetchDefaultSettings"),
      this.getUserApplicationSettignsVisible(),
    ]);
    this.loading = false;
    this.contactRecipients =
      this.getAllContacts && this.getAllContacts.data
        ? [...this.getAllContacts.data]
        : [];
  },
  computed: {
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("fileUpload", ["getUploadStatus"]),
    ...mapGetters("settings", ["getDefaultSettings", "getApplicationSettings"]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("documents", [
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentSaveAsTemplate",
    ]),
    fetchSignUsers() {
      return this.documentUsers.flatMap((element) => element.email);
    },
  },
  methods: {
    async getUserApplicationSettignsVisible() {
      try {
        await this.$store.dispatch("settings/fetchApplicationSettings");
        if (this.getApplicationSettings && this.getApplicationSettings.data) {
          if (this.getApplicationSettings.data.document_signature) {
            this.showSettings =
              this.getApplicationSettings.data.document_signature.show_document_settings_when_sent;
            this.sender_auto_fill =
              this.getApplicationSettings.data.document_signature.allow_sender_auto_fill;
          }
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async checkIsShowSettignsVisible() {
      if (this.showSettings) {
        this.loading = true;
        this.loadingText = "Document Sending Is In Progress...";
        this.docSettingsVisible = true;
        this.loading = false;
      } else {
        this.loading = true;
        this.loadingText = "Document Sending Is In Progress...";
        // this.getNewDocumentUrlForSend();
        await this.createSendDoc();
        this.loading = false;
      }
    },
    async getNewDocumentUrlForSend() {
      this.newDocumentUrl = "";
      let params = {};
      params.keepFileID = false;
      params.pdfSignatures = [];
      params.defaultScale = 1;
      params.pdfTexts = [];
      params.pdfImages = [];
      params.pdfDeletePages = [];
      params.pdfRotatePages = [];
      // params.pdfContent = this.document_url_path
      //   .slice(23, this.document_url_path.length)
      //   .split(".pdf")[0];

      let url = this.document_url_path.split("/");
      let id = url[url.length - 1].split(".")[0];
      params.pdfContent = id;
      params["doc_company_id"] = url[2];
      await this.$store.dispatch("fileUpload/fileTheUpload", params);
      if (this.getUploadStatus.responseCode == 0) {
        // this.newDocumentUrl = this.getUploadStatus.responseMessage
        this.newDocumentUrl = this.getUploadStatus.fileInfos[0].url;
        await this.createSendDoc();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while sending document",
        });
      }
    },
    handleCloseDialog() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    async saveDocumentAsTemplate() {
      if (this.getDraftDocumentCreateData.data.configurable_document_id) {
        let params = {
          configurable_document_id:
            this.getDraftDocumentCreateData.data.configurable_document_id,
          company_document_id:
            this.getDraftDocumentCreateData.data.company_document_id,
          employee_document_id: this.getDraftDocumentCreateData.data._id,
          name: this.documentName,
        };
        await this.$store.dispatch("documents/saveAsTemplate", params);
        if (this.getDocumentSaveAsTemplate) {
          this.$notify({
            title: "Success",
            message: "Document is saved as template",
            type: "success",
          });
          this.$router.push({
            name: "Dashboard",
          });
        } else {
          this.$notify({
            title: "Error",
            message: "Error in saving as template",
            type: "error",
          });
        }
      }
    },
    gotoViewDocument() {
      let draftId = this.getDraftDocumentCreateData.data._id;
      this.$router.push({
        name: "employee-documents-custom-document",
        params: {
          employee_document_id: draftId,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchDocumentDetails() {
      try {
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.id,
          }
        );

        await this.previewDocumentData();
      } catch (err) {
        this.loading = false;
      }
    },
    previewDocumentData() {
      try {
        this.mailCCSendTo = [];
        this.mailSendTo = [];
        this.documentName = this.getConfigurableDocumentDataById["name"];
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.ckeditor_fields &&
          !this.getConfigurableDocumentDataById.ckeditor_fields.length
        ) {
          this.backgroundImages =
            this.getConfigurableDocumentDataById["background_images"];
          this.pages = this.getConfigurableDocumentDataById.pages;
          if (
            this.pages &&
            this.pages.length &&
            this.pages[0].page_base_width
          ) {
            this.storedFormBuilderWidth = this.pages[0].page_base_width;
          }
          // this.document_url_path =
          //   this.getConfigurableDocumentDataById.document_upload_url;
          let tempUrl =
            this.getConfigurableDocumentDataById.document_upload_url;
          let urlSliptArray = tempUrl.split("/");
          if (urlSliptArray[1] != process.env.VUE_APP_APP_TYPE) {
            this.document_url_path = `/${process.env.VUE_APP_APP_TYPE}${tempUrl}`;
          } else {
            this.document_url_path = tempUrl;
          }
        }

        this.templateTitle =
          this.getConfigurableDocumentDataById.template_title || "";
        this.enforceSignatureOrder =
          this.getConfigurableDocumentDataById.enforce_signature_order;
        this.isAnyoneCanApprove =
          this.getConfigurableDocumentDataById.is_anyone_can_approve;
        this.documentUsers =
          this.getConfigurableDocumentDataById.document_users;
        this.documentUsers = this.documentUsers.map((user) => {
          if (user.user_type == "CC") {
            this.mailCCSendTo.push(user.email);
          } else {
            this.mailSendTo.push(user.email);
          }
          return user;
        });
        this.elements = this.pages[0].fields;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    addCCusers() {
      let ccContacts = this.contactRecipients.filter((user) => {
        return (
          this.mailCCSendTo.indexOf(user.email) != -1 &&
          this.fetchSignUsers.indexOf(user.email) == -1
        );
      });
      ccContacts.map((user) => {
        user.fields_required = false;
        user.has_approval_access = false;
        user.type = "RECEIVER";
        user.user_type = "CC";
        user.value = "CC";
        user.contact = user._id;
      });
      Array.prototype.push.apply(this.documentUsers, ccContacts);
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    async createSendDoc() {
      try {
        this.loading = true;
        this.loadingText = "Document Sending Is In Progress...";
        this.docSettingsVisible = false;
        let emailData = this.prepareEmailData();
        let documentData = this.prepareDocumentData();
        this.addCCusers();
        let timeZone = this.getTimeZone();
        // let date = new Date();
        // date.setDate(
        //   date.getDate() +
        //     this.getConfigurableDocumentDataById.document_expiration_settings
        //       .expiration_settings.expire_documents_in_days
        // );
        // let expiration_settings =
        //   this.getConfigurableDocumentDataById.document_expiration_settings;
        // expiration_settings.expiration_settings.document_expired_date = date;

        // let companayDocumentData = this.prepareFields();
        this.documentUsers.find((el) => {
          if (el && el.user_type == "CC" && !el.sent_status) {
            el.sent_status = true;
          }
        });
        if (this.enforceSignatureOrder) {
          this.documentUsers.find((el) => {
            if (
              el &&
              el.type === "SENDER" &&
              el.e_signature_order &&
              (el.e_signature_order > 0 || el.e_signature_order != 0) &&
              el.e_signature_required
            ) {
              this.sender_auto_fill = false;
            }
          });
        }
        let params = {
          document_template_id:
            this.getConfigurableDocumentDataById.company_document_id,
          configurable_document_id: this.$route.params.id,
          document_users: this.documentUsers,
          enforce_signature_order: this.enforceSignatureOrder,
          document_data: documentData,
          sender_time_zone: timeZone,
          is_anyone_can_approve: this.isAnyoneCanApprove,
          // companayDocumentData: companayDocumentData,
          email_data: emailData,
          senderFilledAllFields: this.sender_auto_fill,
          // document_settings: settings || expiration_settings,
        };
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.ckeditor_fields &&
          this.getConfigurableDocumentDataById.ckeditor_fields.length
        ) {
          params.is_from_ck_editor = true;
        }
        await this.$store.dispatch("documents/createSentDocument", params);
        if (this.getDraftDocumentCreateStatus) {
          this.successNotify("Document sent successfully");
          this.docSettingsVisible = false;
          this.sentDocumentSuccess = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    prepareEmailData() {
      let emailData = {};
      let users = [...this.contactRecipients];
      let usersEmail = users.map((user) => user.email);
      emailData["signers"] = this.mailSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }

        return data;
      });

      emailData["to_cc"] = this.mailCCSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }

        return data;
      });
      emailData["data"] = this.mailContent;
      emailData["subject"] = this.mailSubject;
      return emailData;
    },
    prepareDocumentData() {
      let data = {};
      this.elements.forEach((element) => {
        if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.files && element.files.length) {
          data[element.key] = element.files;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    prepareFields() {
      let inOrderFields = this.getFieldsFromElements();
      inOrderFields.sort((a, b) => {
        return a.y - b.y;
      });
      let data = {
        id: this.$route.params.id,
        name: this.documentName,
        type: "CUSTOM",
        configure_type: "DYNAMIC",
        company_document_id:
          this.getConfigurableDocumentDataById.company_document_id,
        background_images: this.backgroundImages,
        document_users: this.documentUsers,
        document_expiration_settings:
          this.getConfigurableDocumentDataById.document_expiration_settings,
        enforce_signature_order:
          this.getConfigurableDocumentDataById.enforce_signature_order,
        is_template: this.getConfigurableDocumentDataById.is_template,
        template_title: this.documentName,
        pages: [
          {
            page_no: 1,
            background: false,
            background_image: "",
            background_images: this.backgroundImages,
            style:
              "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
            fields: inOrderFields,
            page_base_width: this.storedFormBuilderWidth,
            templates_used: "",
            entities_used: "",
          },
        ],
      };

      if (
        this.getConfigurableDocumentDataById.description &&
        this.getConfigurableDocumentDataById.description.length
      ) {
        data.description = this.getConfigurableDocumentDataById.description;
      }

      return data;
    },
    getFieldsFromElements() {
      let duplicateKeyCounter = {};
      return this.elements.map((element, index) => {
        element.sno = index + 1;
        if (!element.template_id) {
          let newKey = this.generateKeyForField(element.key, element.label);
          if (duplicateKeyCounter[newKey]) {
            element.key = newKey + "_" + duplicateKeyCounter[newKey];
            duplicateKeyCounter[newKey]++;
          } else {
            duplicateKeyCounter[newKey] = 1;
            element.key = newKey;
          }
        }

        if (element.type != "my-signature" && element.type != "INITIAL") {
          element.value = "";
          element.source = "";
        }

        if (element.type == "HEADING") {
          element.value = element.content;
          element.source = element.selected_tag;
        }

        if (element.type == "PARAGRAPH") {
          element.value = element.content;
          element.source = "";
        }
        return element;
      });
    },
    generateKeyForField(previousKey, label) {
      if (previousKey) {
        return previousKey;
      }

      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

      return parsedLabel;
    },
  },
  beforeDestroy() {
    this.$store.commit("settings/setDefaultSettings", null, { root: true });
    this.$store.commit("settings/setApplicationSettings", null, { root: true });
    this.$store.commit("documents/setDraftDocumentCreateStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDraftDocumentCreateData", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSaveAsTemplate", null, {
      root: true,
    });
    this.$store.commit("contacts/setAllContacts", null, { root: true });
    this.$store.commit("fileUpload/setUploadStatus", null, { root: true });
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss" scoped>
.send-mail-box {
  max-width: 1100px;
  margin: 0 auto;
  #sendDocumentDialog {
    padding: 0;
    .el-form-item {
      padding: 5px 0px;
      margin-bottom: 5px;
      .el-textarea__inner {
        min-height: 40px !important;
        padding-top: 10px;
      }
      .el-form-item__label {
        line-height: 2;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }
  }
}
.sent-text {
  color: #212529;
  font-size: 22px;
  margin-top: -30px;
  margin-left: 30%;
}
#bing {
  background-color: #f24b91;
}
#doc {
  background-color: #f754a2;
  border: none;
}
.pstyle {
  width: 530px;
  margin-top: 10px;
  margin-left: 16%;
}
.subpstyle {
  margin-top: -15px;
  margin-left: 30%;
}
.imgstyle {
  margin-top: -40px;
  margin-left: 25%;
}
.dialog-footer {
  margin-top: 40px;
  margin-left: 25%;
}
</style>
